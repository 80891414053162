export const PenType = {
  Node: 0,
  Line: 1,
}

export const LockState = {
  None: 0,
  DisableEdit: 1,
  DisableMove: 2,
  DisableScale: 3,
  DisableMoveScale: 4,
  // DisableActive: 5,
  Disable: 10,
}

export const AnchorMode = {
  Default: 0,
  In: 1,
  Out: 2,
}

export const Gradient = {
  None: 0, // 没有渐变
  Linear: 1, // 线性渐变
  Radial: 2, // 镜像渐变
}

// export enum Flip {
//   None, // 正常
//   Horizontal, // 水平翻转
//   Vertical, // 垂直翻转
//   Both, // 全翻转
// }

// 修改哪些属性需要重现计算 textRect
export const needCalcTextRectProps = [
  'text',
  'textWidth',
  'textHeight',
  'textLeft',
  'textTop',
  'fontFamily',
  'fontSize',
  'lineHeight',
  'fontStyle',
  'fontWeight',
  'textAlign',
  'textBaseline',
  'whiteSpace',
  'ellipsis',
  'keepDecimal',
];

export const needSetPenProps = ['x', 'y', 'width', 'height', 'flipX', 'flipY'];

export const needPatchFlagsPenRectProps = [
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'flipX',
  'flipY',
  'visible',
  'showChild',
];

export const needCalcIconRectProps = ['iconLeft', 'iconTop', 'iconRotate'];

export const LineAnimateType = {
  Normal: 0, // 水流
  Beads: 1, // 水珠流动
  Dot: 2, // 圆点
}

/**
 * dom 类型的 图形
 */
export const isDomShapes = [
  'gif',
  'iframe',
  'video',
  'echarts',
  'highcharts',
  'lightningCharts',
];

// 格式刷同步的属性
export const formatAttrs = new Set([
  'borderRadius',
  // 'rotate',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingBottom',
  'progress',
  'progressColor',
  'verticalProgress',
  'reverseProgress',
  // 'flip',
  'flipX',
  'flipY',
  'input',
  'lineDash',
  'lineCap',
  'lineJoin',
  'strokeType',
  'lineGradientFromColor',
  'lineGradientToColor',
  'lineGradientAngle',
  'color',
  'hoverColor',
  'activeColor',
  'lineWidth',
  'bkType',
  'gradientFromColor',
  'gradientToColor',
  'gradientAngle',
  'gradientRadius',
  'hoverBackground',
  'activeBackground',
  'globalAlpha',
  'anchorColor',
  'anchorRadius',
  'shadowColor',
  'shadowBlur',
  'shadowOffsetX',
  'shadowOffsetY',
  'textHasShadow',
  'fontFamily',
  'fontSize',
  'textColor',
  'hoverTextColor',
  'activeTextColor',
  'textBackground',
  'fontStyle',
  'fontWeight',
  'textAlign',
  'textBaseline',
  'lineHeight',
  'whiteSpace',
  'textWidth',
  'textHeight',
  'textLeft',
  'textTop',
  'ellipsis',
  'hiddenText',
  'keepDecimal',
  'borderWidth',
  'borderColor',
  'animateLineWidth',
  'lineAnimateType',
  'frames',
  'animateColor',
  'animateType',
  'animateReverse',
  'background',
  'gradientColors',
  'lineGradientColors',
  'animateLineWidth',
  'gradientSmooth',
]);

/**
 * 清空 pen 的 生命周期
 */
export function clearLifeCycle(pen) {
  pen.onAdd = undefined;
  pen.onValue = undefined;
  pen.onBeforeValue = undefined;
  pen.onDestroy = undefined;
  pen.onMove = undefined;
  pen.onResize = undefined;
  pen.onRotate = undefined;
  pen.onClick = undefined;
  pen.onMouseEnter = undefined;
  pen.onMouseLeave = undefined;
  pen.onMouseDown = undefined;
  pen.onMouseMove = undefined;
  pen.onMouseUp = undefined;
  pen.onShowInput = undefined;
  pen.onInput = undefined;
  pen.onChangeId = undefined;
  pen.onBinds = undefined;
}
