export function mindLine(pen, ctx) {
  const path = !ctx ? new Path2D() : ctx;
  const {x, y, width, height} = pen.calculative.worldRect;
  path.moveTo(x, y + height);
  path.lineTo(x + width, y + height);
  path.closePath();
  if (path instanceof Path2D) {
    return path;
  }
}

export function mindLineAnchors(pen) {
  const points = [
    {
      x: 0,
      y: 1,
    },
    {
      x: 1,
      y: 1,
    },
  ];
  pen.anchors = points.map(({x, y}, index) => {
    return {
      id: index + '',
      x,
      y,
      penId: pen.id,
    };
  });
}
