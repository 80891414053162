import {default as mitt} from 'mitt';
import {defaultOptions} from '../options';
import {globalStore} from './global';

export const EditType = {
  Add: 0,
  Update: 1,
  Delete: 2,
}

export const createStore = () => {
  return {
    data: {
      x: 0,
      y: 0,
      scale: 1,
      pens: [],
      origin: {x: 0, y: 0},
      center: {x: 0, y: 0},
      paths: {},
    },
    histories: [],
    pens: {},
    path2dMap: new WeakMap(),
    animateMap: new WeakMap(),
    active: [],
    animates: new Set(),
    options: {...defaultOptions},
    emitter: mitt(),
    bindData: {},
    bind: {},
    bindVariableData: {},
    visibleBindData: {},
    pensNetwork: {},
    cacheData: [],
    messageEvents: {},
    templatePens: {},
  };
};

// Return a data store, if not exists will create a store.
export const useStore = (id = 'default') => {
  if (!globalStore[id]) {
    globalStore[id] = createStore();
    globalStore[id].id = id;
  }

  return globalStore[id];
};

export const clearStore = (store, template) => {
  const isSame = store.data.template === template;
  if (isSame) {
    //模版一样
    for (const pen of store.data.pens) {
      if (pen.template) {
        store.templatePens[pen.id] = pen;
      }
    }
  }
  store.lastScale = store.data.scale;
  store.data = {
    x: 0,
    y: 0,
    scale: 1,
    pens: [],
    origin: {x: 0, y: 0},
    center: {x: 0, y: 0},
    paths: {},
    template: isSame ? template : null,
  };
  store.sameTemplate = isSame;
  store.pens = {};
  store.histories = [];
  store.historyIndex = null;
  store.path2dMap = new WeakMap();
  store.animateMap = new WeakMap();
  store.bindData = {};
  store.bind = {};
  store.bindVariableData = {}
  store.visibleBindData = {}
  store.pensNetwork = {};
  store.active = [];
  store.hover = undefined;
  store.lastHover = undefined;
  store.animates.clear();
};
