export function pentagram(pen, ctx) {
  if (!pen.onResize) {
    pen.onResize = resize;
  }
  const path = !ctx ? new Path2D() : ctx;
  const {width, height, center} = pen.calculative.worldRect;
  const r = width > height ? height : width;
  //旋转中心点
  const centerX = center.x;
  const centerY = center.y;
  const baseY = centerY - r / 2;
  const baseYi = centerY - r / 4;
  const lx = -(baseYi - centerY) * Math.sin((Math.PI / 180) * 324) + centerX;
  const ly = (baseYi - centerY) * Math.cos((Math.PI / 180) * 324) + centerY;
  path.moveTo(lx, ly);
  for (let i = 0; i < 5; ++i) {
    // TODO: Math.sin Math.cos 考虑优化下
    path.lineTo(-(baseY - centerY) * Math.sin((Math.PI / 180) * 72 * i) + centerX, (baseY - centerY) * Math.cos((Math.PI / 180) * 72 * i) + centerY);
    path.lineTo((lx - centerX) * Math.cos((Math.PI / 180) * 72 * (i + 1)) - (ly - centerY) * Math.sin((Math.PI / 180) * 72 * (i + 1)) + centerX, (lx - centerX) * Math.sin((Math.PI / 180) * 72 * (i + 1)) + (ly - centerY) * Math.cos((Math.PI / 180) * 72 * (i + 1)) + centerY);
  }
  path.closePath();
  if (path instanceof Path2D)
    return path;
}

export function pentagramAnchors(pen) {
  // TODO: 组合状态下的 width height 成了固定的百分比
  const {width, height} = pen;
  const r = width > height ? height : width;
  const anchors = [];
  for (let i = 0; i < 5; ++i) {
    anchors.push({
      flag: 1,
      id: String(i),
      penId: pen.id,
      x: 0.5 + ((r / 2) * Math.sin((Math.PI / 180) * 72 * i)) / width,
      y: ((-r / 2) * Math.cos((Math.PI / 180) * 72 * i)) / height + 0.5,
    });
  }
  pen.anchors = anchors;
}

function resize(pen) {
  // 过滤出非默认锚点，即自定义锚点
  const manualPoints = pen.anchors.filter((point) => point.flag !== 1);
  pentagramAnchors(pen);
  pen.anchors = pen.anchors.concat(...manualPoints);
}
