/**
 * 根据脚本创建一个函数
 *
 * @param paramsArr 参数列表
 * @param script    脚本内容
 * @author zhengqiang
 */
export function createFunction(paramsArr, script) {
  if (!script) {
    throw new Error('script has no content')
  }
  if (!paramsArr) {
    paramsArr = []
  }
  if (!Array.isArray(paramsArr)) {
    paramsArr = [paramsArr]
  }
  return new Function(paramsArr, script)
}

/**
 * 根据脚本创建一个闭包函数，返回一个包含子函数的对象
 *
 * @param paramsArr 参数列表
 * @param funcArr   子函数列表
 * @param script    脚本内容
 * @author zhengqiang
 */
export function createClosePackageFunctions(paramsArr, funcArr, script) {
  if (!script) {
    throw new Error('script has no content')
  }
  if (!funcArr) {
    throw new Error('funcArr is not presented')
  }
  if (!Array.isArray(funcArr)) {
    funcArr = [funcArr]
  }
  if (!paramsArr) {
    paramsArr = []
  }
  if (!Array.isArray(paramsArr)) {
    paramsArr = [paramsArr]
  }
  let startScript = funcArr.map(item => 'var ' + item + ';').join('\n')
  let endScript = `return {${funcArr.map(item => item + ":" + item).join(',')}}`
  return createFunction(paramsArr, startScript + '\n' + script + '\n' + endScript)
}

/**
 * 根据参数列表从对象解析参数数组
 *
 * @param paramsArr 参数列表
 * @param object    参数对象
 * @author zhengqiang
 */
export function getParamValueArr(paramsArr, object) {
  if (!paramsArr) {
    paramsArr = []
  }
  if (!Array.isArray(paramsArr)) {
    paramsArr = [paramsArr]
  }
  if (!object) {
    object = {}
  }
  return paramsArr.map(param => object[param])
}