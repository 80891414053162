import { table } from './table';
import { switcher } from './switcher';
import { slider } from './slider';
import { checkbox } from './checkbox';
import { radio } from './radio';
import { table2 } from './table2';
import { time } from './time';

export function formPens() {
  return {
    radio,
    switcher,
    slider,
    checkbox,
    table,
    table2,
  };
}

export function formPath2DPens() {
  return {
    time,
  };
}
