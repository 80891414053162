export const HoverType = {
  None: 0,
  LineAnchor: 1,
  NodeAnchor: 2,
  Line: 3,
  Node: 4,
  Resize: 5,
  Rotate: 6,
  LineAnchorPrev: 7,
  LineAnchorNext: 8,
}

export const HotkeyType = {
  None: 0,
  Translate: 1,
  Select: 2,
  Resize: 3,
  AddAnchor: 4,
}

export const MouseRight = {
  None: 0,
  Down: 1,
  Translate: 2,
}

export const Direction = {
  None: -1,
  Up: 0,
  Right: 1,
  Bottom: 2,
  Left: 3,
}

export const defaultCursors = [
  'nw-resize',
  'ne-resize',
  'se-resize',
  'sw-resize',
]

export const rotatedCursors = [
  'n-resize',
  'e-resize',
  's-resize',
  'w-resize',
]

export const defaultDrawLineFns = ['curve', 'polyline', 'line'];

export const inheritanceProps = [
  'dash',
  'lineWidth',
  'lineCap',
  'lineJoin',
  'strokeType',
  'color',
  'lineGradientFromColor',
  'lineGradientToColor',
  'lineGradientAngle',
  'globalAlpha',
  'bkType',
  'background',
  'gradientFromColor',
  'gradientToColor',
  'gradientAngle',
  'gradientRadius',
  'fontFamily',
  'fontSize',
  'textColor',
  'hoverTextColor',
  'activeTextColor',
  'textBackground',
  'fontStyle',
  'fontWeight',
  'textAlign',
  'textBaseline',
  'lineHeight',
  'whiteSpace',
  'textLeft',
  'textTop',
  'flipX',
  'flipY',
  'lineDash',
];
