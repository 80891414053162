export class Title {
  parentElement = undefined;
  box = undefined;
  currentAnchor = undefined; // 本次 tooltip 在哪个画笔上
  constructor(parentElement) {
    this.parentElement = parentElement
    this.box = document.createElement('div');
    this.box.className = 'xzd-title';
    parentElement.appendChild(this.box);

    let sheet;
    for (let i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].title === 'xinzhidi.com/title') {
        sheet = document.styleSheets[i];
      }
    }

    if (!sheet) {
      let style = document.createElement('style');
      style.type = 'text/css';
      style.title = 'xinzhidi.com/title';
      document.head.appendChild(style);

      style = document.createElement('style');
      style.type = 'text/css';
      document.head.appendChild(style);
      sheet = style.sheet;
      sheet.insertRule('.xzd-title{position:absolute;padding:0;z-index:10;left: -9999px;top: -9999px;background:#fff;color:#000; cursor: crosshair;border: 1px solid black;}');
    }
  }

  /**
   * @returns 返回设置前的 rect
   */
  setText(anchor) {
    this.box.innerText = anchor.title;
  }

  /**
   * 更新文字
   */
  updateText(anchor) {
    if (this.currentAnchor?.id !== anchor.id) {
      return;
    }
    if (Title.titleEmpty(anchor)) {
      return;
    }

    this.setText(anchor);
    this.changePositionByAnchor(anchor);
  }

  changePositionByAnchor(anchor) {
    this.box.style.left = anchor.x + 10 + 'px';
    this.box.style.top = anchor.y + 10 + 'px';
  }

  static titleEmpty(anchor) {
    return !anchor.title; // && !anchor.titleFn && !anchor.titleFnJs;
  }

  show(anchor, pen) {
    if (Title.titleEmpty(anchor)) {
      return;
    }
    this.currentAnchor = anchor;
    this.setText(anchor);
    let pos = {
      x: pen.calculative.canvas.store.data.x + anchor.x,
      y: pen.calculative.canvas.store.data.y + anchor.y,
    };
    this.changePositionByAnchor(pos);
  }

  hide() {
    this.box.style.left = '-9999px';
    this.box.innerText = '';
    this.currentAnchor = null;
  }

  destroy() {
    this.box.onmouseleave = null;
  }
}
